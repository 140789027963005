import { Col } from "react-bootstrap";

function NewPartner({ partner, language }) {
  console.log(partner);
  return (
    <Col xs={4} sm={3} lg={2} className="single-partner">
      {partner.avatar.length ? (
        <div className="single-partner-image" onClick={() => window.open(`${partner?.url}`)}>
          <img src={partner?.avatar?.at(0)?.url} alt="partner-logo" />
        </div>
      ) : (
        <div className="single-partner-text">
          <span>{partner[`name_${language}`]}</span>
        </div>
      )}
    </Col>
  );
}

export default NewPartner;
